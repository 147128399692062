var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dropdown-wrapper", class: { disabled: _vm.disabled } },
    [
      _vm.labelText
        ? _c("label", { staticClass: "control-label" }, [
            _vm._v(_vm._s(_vm.labelText))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "AppDropdownMenu",
        {
          ref: "app-dropdown-menu",
          staticClass: "app-dropdown-menu h-100",
          class: {
            small: _vm.small,
            disabled: _vm.disabled,
            border: _vm.border
          },
          attrs: {
            options: _vm.optionsSubset,
            imageWidth: _vm.imageWidth,
            label: _vm.label,
            rightAlign: _vm.rightAlign,
            rounded: _vm.rounded,
            roundedIcons: _vm.roundedIcons,
            circleIcons: _vm.circleIcons,
            "manual-control": _vm.manualControl,
            menuTriggerClass: _vm.menuTriggerClass,
            dropdownRowComponent: _vm.dropdownRowComponent,
            dropdownAutoClose: _vm.dropdownAutoClose
          },
          on: { selected: _vm.selectHandler }
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex align-items-center w-100 dropdown-toggle",
              class: { "px-2 py-1": _vm.small },
              staticStyle: { height: "40px" },
              attrs: { id: _vm.toggleId },
              on: { click: _vm.toggle }
            },
            [
              _c(
                "div",
                { staticClass: "h-100 d-flex dropdown-image" },
                [
                  _vm.selectedImage
                    ? _c("AppImage", {
                        staticClass: "mr-2 d-flex",
                        class: { rounded: _vm.roundedIcons },
                        attrs: {
                          src: _vm.selectedImage,
                          cover: "",
                          width: _vm.imageWidth
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchInput,
                    expression: "searchInput"
                  }
                ],
                ref: "search-input",
                staticClass: "flex-grow-1",
                class: { "cursor-pointer": !_vm.searchable },
                style: _vm.labelStyle,
                attrs: {
                  placeholder: _vm.placeholder,
                  size: "1",
                  readonly: !_vm.searchable
                },
                domProps: { value: _vm.searchInput },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchInput = $event.target.value
                  }
                }
              })
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }