var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.creditCardRequired,
          expression: "creditCardRequired"
        }
      ]
    },
    [
      _vm.stripe
        ? _c("CreditCardInput", {
            staticClass: "my-2",
            attrs: { "pre-auth": true, stripe: _vm.stripe },
            on: {
              update: function($event) {
                return _vm.setCard($event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.stripe_error
        ? _c(
            "div",
            {
              attrs: {
                id: "cardErrorMessage",
                "data-test-id": "card-error-message"
              }
            },
            [_vm._v(_vm._s(_vm.stripe_error))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "AppModal",
        {
          ref: "modal",
          attrs: {
            staticDataBackdrop: true,
            hideClose: true,
            title: "Looks like you may already have an account!",
            action: "Yes, Start My " + _vm.trialDays + "-Day Trial",
            "secondary-action": "Login to Existing Account"
          },
          on: {
            cta: _vm.createSubscription,
            "secondary-cta": _vm.goToLoginWithExistingEmail
          }
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.subheading) + "\n\t\t")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }