var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "color-picker-wrapper" },
    [
      _c("chrome-picker", {
        attrs: { "disable-alpha": true, "disable-fields": true },
        model: {
          value: _vm.localValue,
          callback: function($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue"
        }
      }),
      _vm._v(" "),
      _c("twitter-picker", {
        attrs: {
          "disable-alpha": true,
          "disable-fields": true,
          triangle: "hide",
          "default-colors": _vm.limitPresets
        },
        model: {
          value: _vm.localValue,
          callback: function($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }