<template>
	<AppModal
		ref="insert-video-modal"
		id="insert-video-modal"
		title="Add a Video"
		class-name="block-modal fade"
		:close-button="false"
		:backdrop="false"
	>
		<div class="d-flex-column justify-content-left col-sm mb-3 mt-3">
			<AppInput
				:error="errorText"
				v-model.trim="link.href"
				icon="/images/icons/ic-link.svg"
				class="form-group"
				placeholder="Paste a YouTube, Loom, or Wistia video link here..."
			/>
		</div>
		<div class="d-flex flex-column gap v-gap mt-3 pt-1 col-sm">
			<AppButton name="cta-button-modal-insert-video-modal" :disabled="!link.href" @click="handleSubmit">Embed Video</AppButton>
			<AppButton name="secondary-cta-button-modal-insert-video-modal" outline @click="uploadVideo" icon="/images/icons/ic-upload.svg"
				>Upload Your Own
			</AppButton>
		</div>
	</AppModal>
</template>
<script>
	import helpers from '~/global_helper/helpers.js'

	export default {
		components: {},
		props: {
			editor: {
				type: Object,
				required: true,
			},
			attributes: {
				type: Object,
				default: null,
			},
		},
		data() {
			return {
				link: {
					href: '',
				},
				errorText: [],
			}
		},
		methods: {
			handleCancel() {
				$(this.$refs['insert-video-modal'].$el).modal('hide')
				this.errorText = []
			},
			getEmbedHTML(url) {
				let videoId = ''
				let platform = ''

				// Check for Loom URL
				const loomRegex = /https:\/\/www\.loom\.com\/share\/([a-zA-Z0-9]+)/
				const loomMatch = url.match(loomRegex)
				if (loomMatch) {
					videoId = loomMatch[1]
					platform = 'loom'
				}

				// Check for YouTube URL
				// eslint-disable-next-line no-useless-escape
				const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?|watch)(?:\/|(?:\?v=))|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
				const youtubeMatch = url.match(youtubeRegex)
				if (youtubeMatch) {
					videoId = youtubeMatch[1]
					platform = 'youtube'
				}

				// Check for Wistia URL
				const wistiaRegex = /(?:https?:\/\/)?(?:www\.)?(?:wistia\.com\/medias\/|fast\.wistia\.net\/embed\/medias\/)([a-zA-Z0-9]+)(?:[?&].*)?/
				const wistiaMatch = url.match(wistiaRegex)
				if (wistiaMatch) {
					videoId = wistiaMatch[1]
					platform = 'wistia'
				}

				// Generate the embed HTML based on the platform
				if (videoId && platform) {
					switch (platform) {
						case 'loom':
							return `<iframe src="https://www.loom.com/embed/${videoId}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen ></iframe>`
						case 'youtube':
							return `<iframe src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`
						case 'wistia':
							return `<iframe src="https://fast.wistia.net/embed/iframe/${videoId}" frameborder="0" allow="autoplay fullscreen" allowfullscreen></iframe>`
						default:
							return ''
					}
				}
				this.errorText = ['Invalid video link.']
				return ''
			},
			handleSubmit() {
				const url = this.link.href
				if (!url) {
					this.errorText = ['Please enter a video link.']
					return
				}

				const embedHTML = this.getEmbedHTML(url)

				if (embedHTML) {
					this.editor
						.chain()
						.focus()
						.insertContent(embedHTML)
						.run()
					this.tick += 1
					this.handleCancel()
				}
			},
			assignForm() {
				this.link.text = this.attributes.text
				this.link.href = this.attributes.href
			},
			onLoad() {
				if (this.attributes) {
					this.assignForm()
				}
				$(this.$refs['insert-video-modal'].$el).modal('show')
			},
			appendLink() {
				this.link.href = helpers.autoAddHttps(this.link.href)
				this.$v.link.href.$touch()
			},
			uploadVideo() {
				$(this.$refs['insert-video-modal'].$el).modal('hide')
				this.$emit('uploadVideo')
			},
		},
	}
</script>
