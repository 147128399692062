var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row add-product-card flex-nowrap",
      class: { "disabled-bg": _vm.isDisabled }
    },
    [
      _c("div", { staticClass: "col-auto" }, [
        !_vm.iconLoaded
          ? _c("div", {
              staticClass: "icon-placeholder-div",
              style: "background-color: " + _vm.color + " !important;"
            })
          : _vm._e(),
        _vm._v(" "),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.iconLoaded,
              expression: "iconLoaded"
            }
          ],
          attrs: { src: _vm.image, alt: _vm.title },
          on: { load: _vm.handleImageLoad }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col pl-1" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-column justify-content-center h-100" },
          [
            _c(
              "h5",
              {
                staticClass: "header",
                class: { "disabled-text": _vm.isDisabled }
              },
              [_vm._v(_vm._s(_vm.title))]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "description",
                class: { "disabled-text": _vm.isDisabled }
              },
              [_vm._v(_vm._s(_vm.description))]
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }