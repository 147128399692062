<template>
	<div class="backdrop">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'AuthContainer',
	}
</script>

<style lang="scss" scoped>
	.backdrop {
		height: 100vh;
		@supports (height: 100dvh) {
			height: 100dvh;
		}
		min-width: 100vw;
		overflow: scroll;
		&:after {
			content: '';
			background: url(~/assets/images/stanbg.svg);
			width: 100%;
			height: 100%;
			position: absolute;
			pointer-events: none;
			left: 0;
			top: 0;
			z-index: -1;
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}
	}
</style>
