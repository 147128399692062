<template>
	<div class="color-picker-wrapper">
		<chrome-picker :disable-alpha="true" :disable-fields="true" v-model="localValue" />
		<twitter-picker :disable-alpha="true" :disable-fields="true" v-model="localValue" triangle="hide" :default-colors="limitPresets" />
	</div>
</template>
<script>
	import { Chrome, Twitter } from 'vue-color'

	export default {
		components: {
			'chrome-picker': Chrome,
			'twitter-picker': Twitter,
		},
		props: {
			value: {
				type: String,
				default: '#000',
			},
			presets: {
				type: Array,
				default: () => ['#6355FF', '#FF6A00', '#FF058C', '#FED440', '#70E187', '#8000ff', '#000000'],
			},
		},
		model: {
			prop: 'value',
			event: 'valueChange',
		},
		computed: {
			localValue: {
				get: function getter() {
					return this.value
				},
				set: function setter(value) {
					this.$emit('valueChange', value.hex)
				},
			},
			limitPresets() {
				return this.presets.length > 7 ? this.presets.slice(0, 700) : this.presets
			},
		},
	}
</script>
<style lang="scss" scoped>
	.color-picker-wrapper {
		background-color: transparent;
		padding: 1.25rem;
		// box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.07);
		// border-radius: 20px;
		@media (min-width: $sm) {
			padding: 1.5rem;
		}
		.vc-chrome,
		.vc-twitter {
			box-shadow: none;
		}
		.vc-chrome::v-deep {
			width: 100%;
			margin-bottom: 1rem;

			.vc-chrome-saturation-wrap {
				border-radius: 10px;
				margin-bottom: 1rem;
			}
			.vc-chrome-body {
				padding: 0;
				.vc-chrome-color-wrap {
					display: none;
				}
				.vc-chrome-hue-wrap {
					.vc-hue {
						border-radius: 100px;
					}
				}
			}
		}
		.vc-twitter::v-deep {
			.vc-twitter-body {
				padding: 0;
				.vc-twitter-swatch {
					width: 40px;
					height: 25px;
					margin: 0 15px 20px 0;
				}
				.vc-twitter-hash {
					margin-top: -6px;
					background-color: var(--stan-gray-light-color);
					width: auto;
					padding: 14px 5px 14px 12px;
					color: var(--stan-text-light-color);
					height: 38px;
				}
				.vc-input__input {
					margin-top: -6px;
					padding: 14px 12px;
					padding-left: 0;
					background-color: var(--stan-gray-light-color);
					color: var(--stan-text-dark-color);
					box-shadow: none;
					height: 10px;
				}
			}
		}
	}
</style>
